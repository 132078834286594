import { AxiosResponse } from "axios"
import { LatinaClient, LatinaClientToken } from "./LatinaClient"

export class LatinaAPI {
  static API_URL = ''

  statement(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/statement-full`, data)
  }

  subscriptionCreate(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/subscription-create`, data)
  }

  userCreate(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/user-create`, data)
  }

  authLogin(data: any): Promise<AxiosResponse<any>> {
    return LatinaClient.post(`${LatinaAPI.API_URL}/auth/login`, data)
  }

  profile(): Promise<AxiosResponse<any>> {
    return LatinaClientToken.get(`${LatinaAPI.API_URL}/users/profile`)
  }

  statementAll(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/statement-all`, data)
  }

  studentAll(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/student-all`, data)
  }

  studentOne(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/student-one`, data)
  }

  teacherAll(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/teacher-all`, data)
  }

  directionAll(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/direction-all`, data)
  }

  studentObjSubAll(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/student-obj-sub-all`, data)
  }

  subscriptionAll(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/subscription-all`, data)
  }

  subscriptionOne(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/subscription-one`, data)
  }

  statementOne(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/statement-one`, data)
  }

  userBirthday(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/user-birthday`, data)
  }

  statementLessonAll(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/statement-lesson-all`, data)
  }

  lessonAll(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/lesson-all`, data)
  }

  token(): Promise<AxiosResponse<any>> {
    return LatinaClientToken.get(`${LatinaAPI.API_URL}/latina/token`)
  }

  me(): Promise<AxiosResponse<any>> {
    return LatinaClientToken.get(`${LatinaAPI.API_URL}/latina/me`)
  }

  addSubscript(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/latina-add-subscript`, data)
  }

  addStatement(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/latina-add-statement`, data)
  }

  dataStatAll(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/latina-data-stat`, data)
  }

  subscriptionExtension(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/subscription-extension`, data)
  }

  statZp(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/stat-zp`, data)
  }

  subscriptionDelete(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/subscription-delete`, data)
  }

  lessonDelete(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/lesson-delete`, data)
  }

  infoUser(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/info-user`, data)
  }

  studentObjSubSpez(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/student-obj-sub-spez`, data)
  }

  studentObjSubNotSpez(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/student-obj-sub-not-spez`, data)
  }

  statementTeacher(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/statement-teacher`, data)
  }

  statementTeacherOne(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/statement-teacher-one`, data)
  }

  ratingStudentSub(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/rating-student-sub`, data)
  }

  subscriptionTelegram(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/service-subscription-telegram`, data)
  }

  subscriptionCreateSpez(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/subscription-create-spez`, data)
  }

  ratingStudentLess(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/rating-student-less`, data)
  }

  subscriptionExtensionHalfMonth(data: any): Promise<AxiosResponse<any>> {
    return LatinaClientToken.post(`${LatinaAPI.API_URL}/latina/subscription-extension-half-month`, data)
  }
}

export default new LatinaAPI()
